import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Practicebreadcreumb from "../components/learn/practice-breadcumbs";

import Practiceowncontent from "../components/learn/practice-content";

// markup
const PracticeownPage = () => {
  return (
    <div>
      <Seo title="Learn" />
      <Layout isSticky>
        <section>
          <Practicebreadcreumb />
        </section>
        <section>
        <Practiceowncontent />
        </section>
      </Layout>
    </div>
  );
};

export default PracticeownPage;
