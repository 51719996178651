import React from "react";

import ltmlogo from "../../assets/images/learn/LTM-logo_updated.png";

import { Link } from "gatsby";

export default function Practiceowncontent() {
  return (
    <section className="practiseown-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
            <div class="activity1">
              <h3 class="font-light">Heartfulness</h3>
              <h4 class="font-light">Stages of Practice</h4>
              <div class="stage-prac py-5">
                <img className="img-fluid mg-bottom20" src={ltmlogo} alt="Heartssapp" />
                <p class="font-book theme-p-tag">List of Activities</p>
                <p class="text-left wid900-cent theme-p-tag">
                  1.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Heartfulness relaxation followed by meditation with
                    transmission
                  </Link>
                  <br />
                  2.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+2.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Why the heart?
                  </Link>
                  <br />
                  3.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+3.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    How to deal with thoughts in meditation?{" "}
                  </Link>
                  <br />
                  4.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+4.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    What is transmission?{" "}
                  </Link>
                  <br />
                  5.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+5.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Teaching others to relax
                  </Link>
                  <br />
                  6.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+6.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Deepening meditation by the practice of rejuvenation
                  </Link>
                  <br />
                  7.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+7.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Deepening meditation by connecting with your source
                  </Link>
                  <br />
                  8.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+8.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Individual meditation at home
                  </Link>
                  <br />
                  9.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+9.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sharing relaxation and meditation with your friends and
                    family
                  </Link>
                  <br />
                  10.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+10.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    What brings happiness
                  </Link>
                  <br />
                  11.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+11.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Daily routine
                  </Link>
                  <br />
                  12.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+12.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Attitude for meditation
                  </Link>
                  <br />
                  13.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+13.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Experience: observing and sharing
                  </Link>
                  <br />
                  14.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+14.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Conscious living: how to change?{" "}
                  </Link>
                  <br />
                  15.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+15.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to stay centred and not react to difficulties?
                  </Link>
                  <br />
                  16.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+16.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Getting a good night’s sleep
                  </Link>
                  <br />
                  17.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+17.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Excel in whatever you do
                  </Link>
                  <br />
                  18.{" "}
                  <Link
                    class="activity"
                    to="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+18.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Observe yourself and write a diary
                  </Link>
                  <br />
                  19.{" "}
                  <Link
                    class="activity"
                    href="//cdn-prod.heartfulness.org/hfn-content/Stages+of+Practice_Activity+19.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    What have you discovered about yourself?
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
